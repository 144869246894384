import React, { useCallback, useMemo, useState } from 'react';

interface HeaderProviderProps {
  children: React.ReactNode;
}
interface Header {
  isHidden?: boolean;
  isWhite?: boolean;
  isBackButton?: boolean;
  isSkipButton?: boolean;
  hasMask?: boolean;
  children?: any;
  isMenuOpen?: boolean;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  onBack?: () => void;
  onSkip?: () => void;
}

export interface HeaderContextType {
  header: Header;
  setHeader(header: Header): void;
}

const defaultContext: HeaderContextType = {
  header: {
    isHidden: false,
    isWhite: false,
    isBackButton: false,
    isSkipButton: false,
    hasMask: false,
    isMenuOpen: false,
    onMenuOpen: () => null,
    onMenuClose: () => null,
    onBack: () => null,
    onSkip: () => null,
  },
  setHeader: () => null,
};

export const HeaderContext = React.createContext<HeaderContextType>(defaultContext);

export const HeaderProvider = ({ children }: HeaderProviderProps): JSX.Element => {
  const [header, setHeader] = useState(defaultContext.header);

  const handleSetHeader = useCallback((newHeader: Header) => {
    setHeader({ ...newHeader });
  }, []);

  const contextValue: HeaderContextType = useMemo(
    () => ({
      header,
      setHeader: handleSetHeader,
    }),
    [header, handleSetHeader]
  );

  return <HeaderContext.Provider value={contextValue}>{children}</HeaderContext.Provider>;
};
