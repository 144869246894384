import { PageState } from 'constants/enum';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import localStorage from 'utils/localStorage';

interface ProgressProviderProps {
  children: React.ReactNode;
}

export type Progress = {
  gameOne: boolean;
  gameTwo: boolean;
  gameThree: boolean;
  lastState?: PageState;
};
export interface ProgressContextType {
  progress: Progress;
  setProgress: (progress: Partial<Progress>) => void;
}

const defaultContext: ProgressContextType = {
  progress: {
    gameOne: false,
    gameTwo: false,
    gameThree: false,
    lastState: undefined,
  },
  setProgress: () => null,
};

export const ProgressContext = createContext<ProgressContextType>(defaultContext);

export const ProgressProvider = ({ children }: ProgressProviderProps): JSX.Element => {
  const [progress, setProgress] = useState<Progress>(localStorage.get('progress'));

  const handleSetProgress = useCallback((newProgress: Progress) => {
    setProgress(prevProgress => {
      const newCombinedProgress = { ...prevProgress, ...newProgress };
      if (localStorage.get('cookies')?.saveProgress) {
        localStorage.set('progress', newCombinedProgress);
      }

      return newCombinedProgress;
    });
  }, []);

  const contextValue: ProgressContextType = useMemo(
    () => ({
      progress: progress,
      setProgress: handleSetProgress,
    }),
    [progress, handleSetProgress]
  );

  return <ProgressContext.Provider value={contextValue}>{children}</ProgressContext.Provider>;
};

export const getNextGame = (progress: Progress) => {
  if (!progress?.gameOne) return PageState.GAME_ONE;
  if (!progress?.gameTwo) return PageState.GAME_TWO;
  if (!progress.gameThree) return PageState.GAME_THREE;

  return PageState.COMING_SOON;
};
