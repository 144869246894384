import React, { useCallback, useEffect, useMemo, useState } from 'react';

interface SettingsProviderProps {
  children: React.ReactNode;
}

interface Settings {
  showUV: boolean;
}

export interface SettingsContextType {
  settings: Settings;
  setSettings(settings: Settings): void;
}

const defaultContext: SettingsContextType = {
  settings: {
    showUV: true,
  },
  setSettings: () => null,
};

export const SettingsContext = React.createContext<SettingsContextType>(defaultContext);

export const SettingsProvider = ({ children }: SettingsProviderProps): JSX.Element => {
  const [settings, setSettings] = useState(defaultContext.settings);

  const handleSetSettings = useCallback((newSettings: Settings) => {
    setSettings({ ...newSettings });
  }, []);

  useEffect(() => {
    setSettings(defaultContext.settings);
  }, []);

  const contextValue: SettingsContextType = useMemo(
    () => ({
      settings,
      setSettings: handleSetSettings,
    }),
    [settings, handleSetSettings]
  );

  return <SettingsContext.Provider value={contextValue}>{children}</SettingsContext.Provider>;
};
