import React, { useCallback, useEffect, useMemo, useState } from 'react';

interface RedirectsProviderProps {
  children: React.ReactNode;
}

interface Redirects {
  detectiveVideo?: boolean;
  landingPageCaseFiles?: boolean;
  gameState?: string;
  restartTraining?: boolean;
  tryAgain?: boolean;
}

export interface RedirectsContextType {
  redirects: Redirects;
  setRedirects(redirects: Redirects): void;
}

const defaultContext: RedirectsContextType = {
  redirects: {
    detectiveVideo: false,
    landingPageCaseFiles: false,
    gameState: null,
    restartTraining: false,
    tryAgain: false,
  },
  setRedirects: () => null,
};

export const RedirectsContext = React.createContext<RedirectsContextType>(defaultContext);

export const RedirectsProvider = ({ children }: RedirectsProviderProps): JSX.Element => {
  const [redirects, setRedirects] = useState(defaultContext.redirects);

  const handleSetRedirects = useCallback((newRedirects: Redirects) => {
    setRedirects({ ...newRedirects });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setRedirects(defaultContext.redirects);
    }, 1000);
  }, [redirects]);

  const contextValue: RedirectsContextType = useMemo(
    () => ({
      redirects,
      setRedirects: handleSetRedirects,
    }),
    [redirects, handleSetRedirects]
  );

  return <RedirectsContext.Provider value={contextValue}>{children}</RedirectsContext.Provider>;
};
