import { css, FlattenSimpleInterpolation } from 'styled-components';

// ====================================================================
// Breakpoints
//
// Window breakpoints
// ====================================================================
export const breakpointTablet = 768;
export const breakpointDesktop = 1024;
export const breakpointLarge = 1920;
export const breakpointMobileSmallHeight = 750;

// ====================================================================
// Additional height breakpoints
//
// Used to better align content vertically
// ====================================================================

export const heightBreakpointMediumMobile = 680;
export const heightBreakpointLargeMobile = 800;

// ====================================================================
// Design width
//
// Used to keep px() proportional to designs
// ====================================================================
export const designWidthMobile = 375;
export const designWidthDesktop = 1440;

// ====================================================================
// Media Mixins
// ====================================================================
export const mediaMobile = content => css`
  @media (max-width: ${breakpointTablet - 1}px) {
    ${content}
  }
`;

export const mediaMobileSmallHeight = content => css`
  @media (max-device-height: ${breakpointMobileSmallHeight}px) {
    ${content}
  }
`;

export const mediaTablet = content => css`
  @media (min-width: ${breakpointTablet}px) {
    ${content}
  }
`;

export const mediaDesktop = content => css`
  @media (min-width: ${breakpointDesktop}px) {
    ${content}
  }
`;

export const mediaLarge = content => css`
  @media (min-width: ${breakpointLarge}px) {
    ${content}
  }
`;

export const mediaLandscape = (content: string) => css`
  @media (orientation: landscape) {
    ${content}
  }
`;

export const mediaPortrait = (content: string) => css`
  @media (orientation: portrait) {
    ${content}
  }
`;

export const mediaHeightMediumMobile = (content: string | FlattenSimpleInterpolation) => css`
  @media (min-height: ${heightBreakpointMediumMobile}px) and (max-aspect-ratio: 10/19) {
    ${content}
  }
`;

export const mediaHeightLargeMobile = (content: string | FlattenSimpleInterpolation) => css`
  @media (min-height: ${heightBreakpointLargeMobile}px) and (max-aspect-ratio: 10/19) {
    ${content}
  }
`;

export const mediaOnlySafari = (content: string | FlattenSimpleInterpolation) => css`
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      ${content}
    }
  }
`;

// ====================================================================
// Dynamic responsiveness.
//
// It keeps everything in proportion on slightly diffeent screen sizes. We will not have to worry about overlaps happening
// on narrow devices such as iPhone 5 (320px wide) or too much space on large devices like iPhone 8 Plus (414px wide).
// We just need to make sure to use the px() mixing FOR EVERYTHING (except things that are required to be 1px, like borders)
// Just copy the value you see on Figma and everything should stay proportional at all times.
// ====================================================================
export const dynamicFontSize = () => css`
  font-size: ${(100 / designWidthMobile) * 10}vw;

  ${mediaTablet(css`
    font-size: ${(100 / designWidthDesktop) * 10}vw;
  `)}

  ${mediaDesktop(css`
    font-size: ${(100 / designWidthDesktop) * 10}vw;
  `)}

  ${mediaLarge(css`
    font-size: ${(breakpointLarge / designWidthDesktop) * 10}px;
  `)}
`;
