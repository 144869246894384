import { ShareType } from 'constants/enum';
import { Howl, Howler } from 'howler';
import { copyToClipboard } from 'utils/copyToClipboard';
import AdobeAnalytics from 'utils/adobeAnalytics';
export default class ShareService {
  private static _instance;
  private _currentGame = 1;
  private options: any = {};
  url = () =>
    process.env.ENV !== 'production'
      ? 'https://creative4-3136-fb10-test-7hmpcc3lga-ew.a.run.app/'
      : 'https://murderisland.channel4.com/';

  UISound: Howl;

  static get instance() {
    if (!ShareService._instance) {
      ShareService._instance = new ShareService();
    }
    return ShareService._instance;
  }

  setGameData = (game, options) => {
    this._currentGame = game;
    this.options = options;
  };

  share = type => {
    AdobeAnalytics.trackShare(
      'game' + this._currentGame + '_share',
      'all4.social_share',
      type.toLowerCase(),
      'game' + this._currentGame
    );

    switch (type) {
      case ShareType.facebook:
        this.shareFacebook();
        break;
      case ShareType.twitter:
        this.shareTwitter();
        break;

      case ShareType.linkedin:
        this.shareLinkedIn();
        break;

      case ShareType.pinterest:
        this.sharePinterest();
        break;

      case ShareType.copylink:
        this.copyLink();
        break;
      case ShareType.email:
        this.shareMail();

        break;

      default:
        break;
    }
  };

  getUrl = () => {
    if (this.options && this.options?.badge) {
      return this.url() + 'share/04/index.html';
    }
    if (this._currentGame === 1) {
      return this.url() + 'share/00/index.html';
    } else if (this._currentGame === 2 && this.options?.time !== undefined) {
      if (this.options?.time && this.options.time <= 60) return this.url() + 'share/01/index.html';
      else if (this.options.time <= 90) return this.url() + 'share/02/index.html';
      else return this.url() + 'share/03/index.html';
    } else if (this._currentGame === 3) {
      return this.url() + 'share/05/index.html';
    } else return this.url();
  };

  getOgImage = shareCode => {
    if (shareCode.match(/[0-9]{2}/) !== null) {
      return this.url() + shareCode + '.png';
    } else {
      return this.url() + 'murder_island_killer.png';
    }
  };

  getQueryParam = (param, location) => {
    if (location) {
      const result = location.match(new RegExp('(\\?|&)' + param + '(\\[\\])?=([^&]*)'));
      return result ? result[3] : null;
    }
    return null;
  };

  shareFacebook = () => {
    window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + this.getUrl(),
      'facebook-share-dialog',
      'width=800,height=600'
    );
  };

  shareTwitter = () => {
    window.open('https://twitter.com/intent/tweet?url=' + this.getUrl());
  };
  copyLink = () => {
    copyToClipboard(this.getUrl());
  };

  shareLinkedIn = () => {
    window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + this.getUrl());
  };

  sharePinterest = () => {
    window.open('https://pinterest.com/pin/create/button/?url=' + this.getUrl() + '&media=&description=');
  };

  shareMail = () => {
    const urlObj = {
      base: 'mailto:?&',
      params: {
        subject: "I've solved the case - Murder Island on Channel 4",
        body: `Click ${this.getUrl()} to access the website and become a detective.`,
      },
    };

    const url = `${urlObj.base}${Object.entries(urlObj.params)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')}`;

    window.open(url);
  };
}
