export enum PageState {
  LANDING_PAGE,
  DETECTIVE_CALL,
  DETECTIVE_FEEDBACK,
  UNLOCK,
  BADGE,
  GAME_ONE,
  GAME_TWO,
  GAME_THREE,
  COMING_SOON,
  CONGRATULATIONS,
  MURDER_ISLAND,
  FINGERPRINTS,
}

export enum CallState {
  CALLING,
  CALL_ANSWERED,
  CALL_ENDED,
  CHAT,
}

export enum ShareType {
  facebook = 'Facebook',
  twitter = 'Twitter',
  linkedin = 'LinkedIn',
  pinterest = 'Pinterest',
  email = 'E-MAIL',
  copylink = 'COPY LINK',
}

export enum GameVersionState {
  MAIN,
  GAME_ONE,
  GAME_TWO,
  GAME_THREE,
}
