import React, { useCallback, useMemo, useState } from 'react';

interface HistoryProviderProps {
  children: React.ReactNode;
}

export interface HistoryContextType {
  history: string[];
  setHistory(patch: string): void;
}

const defaultContext: HistoryContextType = {
  history: [],
  setHistory: () => null,
};

export const HistoryContext = React.createContext<HistoryContextType>(defaultContext);

export const HistoryProvider = ({ children }: HistoryProviderProps): JSX.Element => {
  const [historyState, setHistoryState] = useState(defaultContext.history);

  const patchState = useCallback((patch: string) => {
    setHistoryState(prev => [...prev, patch]);
  }, []);

  const contextValue: HistoryContextType = useMemo(
    () => ({
      history: historyState,
      setHistory: patchState,
    }),
    [historyState, patchState]
  );

  return <HistoryContext.Provider value={contextValue}>{children}</HistoryContext.Provider>;
};
